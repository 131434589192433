






































































import moment from 'moment'
import { Component, Ref, Vue } from 'vue-property-decorator'
import ListView from '@/components/ListView.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'
import CSVExportBtn from '@/components/CSVExport.vue'

@Component({
  components: {
    ListView,
    ShopAutocomplete,
    DateSelect,
    CSVExportBtn,
  },
})
export default class AdditionalReserveFrame extends Vue {
  filterDefinition = {
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
  }
  @Ref() readonly listView!: ListView

  get staffLoggedIn(): boolean {
    return this.$store.getters.staffLoggedIn
  }
  get csvHeaders() {
    return CSVHeaders
  }

  get headers() {
    return [
      { text: '店コード', value: 'shopCode' },
      { text: '店舗', value: 'shopName' },
      { text: '日付', value: 'date' },
      { text: '時間', value: 'time' },
    ]
  }
  dateFmt(val: string) {
    if (!val) return val
    return moment(val).format('YYYY-MM-DD')
  }
  timeFmt(val: string) {
    if (!val) return val
    return moment(val).format('HH:mm')
  }

  filter: null | Record<string, any> = null

  convertFetchParams(params: Record<string, any>) {
    if (params.dateFrom) {
      params.dateFrom = moment(params.dateFrom)
        .startOf('day')
        .toISOString()
    }
    if (params.dateTo) {
      params.dateTo = moment(params.dateTo)
        .endOf('day')
        .toISOString()
    }
    return params
  }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
  }
}

const extractTime = (value: string | undefined) =>
  value ? moment(value).format('HH:mm') : ''
const extractDate = (value: string | undefined) =>
  value ? moment(value).format('YYYY-MM-DD') : ''

const CSVHeaders = [
  { key: 'id', label: '追加枠ID' },
  { key: (item: any) => item.shopCode, label: '店コード' },
  { key: (item: any) => item.shopName, label: '店舗名' },
  { key: (item: any) => extractDate(item.startAt), label: '日付' },
  {
    key: (item: any) => extractTime(item.startAt),
    label: '時間',
  },
]
